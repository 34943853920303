var imglgH;
var imglgW;

$(document).ready(function(){
	initColorBox();

	// init Infinite Scroll
	
	setTimeout(function(){
		initInfiniteScroll();
		getFilterAmounts();
	}, 0);

	$(".cb_filter").change(function() {
		resetSearchResult();
	});
	$(document).on('click', '#addon-search', function(e) {
		e.preventDefault();
		resetSearchResult();
	});
	$(document).on('keypress', '#search', function(e) {
		if (e.keyCode == 13) { resetSearchResult(); }
	});

	switchLanguage(getPreferredLanguage());

	$('.carousel').carousel({
		interval: 10000
	});
	
	setWHVars();
	
	$( window ).resize(function() {
		setWHVars();
	});
	$(document).on('click', '.result:not(.no-results)', function(e) {
		e.preventDefault();
		openProduct($(this));
	});
	$(document).on('click', '.imgsm', function(e) {
		if (MOBILE)
		{
			e.preventDefault();
			$(".imglg").empty();
	
			var vid						= JSO.toString($(this).data("vidurl")) != "";
			
			if (vid)
			{
				if (!$(".imglg").hasClass("vid"))	{ $(".imglg").addClass("vid"); }
				
				$(".imglg").css('background-image', "");
				$(".imglg").attr('title', $(this).attr("title"));
				$(".imglg").append('<iframe width="' + imglgW + '" height="' + imglgH + '" src="' + $(this).data("vidurl") + '" frameborder="0" allowfullscreen=""></iframe>');
				initColorBox();
			}
			else
			{
				if ($(".imglg").hasClass("vid"))	{ $(".imglg").removeClass("vid"); }
				
				$(".imglg").css('background-image', $(this).css('background-image').replace(/\/resize\/\d+x\d+/gi, ""));
				$(".imglg").attr('title', $(this).attr("title"));
				$(".imglg").append('<a class="colorbox" src="' + $("a.colorbox", $(this)).attr("href") + '" title="' + $(this).attr("title") + '"></a>');
				initColorBox();
			}
		}
	});
});

function initInfiniteScroll()
{
	try
	{
		var $grid				= $('.results');

		if ($grid.length > 0)
		{
			$grid.infiniteScroll({
				path: function() {
					return $(".pagination__next").last().attr("href");
				},
				checkLastPage: '.pagination__next',
				append: '.result',
				history: false,
				status: '.scroll-load-status',
				scrollThreshold: 800,
				prefill: true,
				usepost: true
			});

			$grid.on('last.infiniteScroll', function(event, response, path) { $(".scroll-load-status").remove(); });
		}
	}
	catch (ex)					{ /* no problem */ }
}

function initColorBox()
{
	try { $.colorbox.remove("a.thickbox, a.colorbox, area.colorbox, input.colorbox, a.colorboxvid"); } catch (e) { /*no problem*/ }
	try
	{
		if (MOBILE)				{ $("a.thickbox, a.colorbox, area.colorbox, input.colorbox").attr("target", "_blank"); }
		else
		{
			$("a.thickbox, a.colorbox, area.colorbox, input.colorbox").colorbox({ maxWidth:'95%', maxHeight:'95%', rel:'gal' });
			$("a.colorboxvid").colorbox({ width:'600px', height:'400px', rel:'gal', iframe: true });
		}
	}
	catch (ex)					{ /*no problem*/ }
}

function resetSearchResult()
{
	var $grid				= $('.results');
	$grid.infiniteScroll('destroy');
	$grid.data('infiniteScroll', null);

	$grid.empty();
	$grid.html("<a class=\"pagination__next\" href=\"/jsp/ducker/searchc.jsp?domcod=" + $grid.data("domcod") + "&page=0\"></a>");

	initInfiniteScroll();
	getFilterAmounts();
}

function setWHVars()
{
	if ($(".imglg").length)
	{
		imglgH					= $(".imglg").outerHeight();
		imglgW					= $(".imglg").outerWidth();
	}
}

function getPreferredLanguage()
{
	var storedLanguage			= localStorage.getItem('language');

	if (storedLanguage)			{ return storedLanguage; }
	else						{ return navigator.language || navigator.userLanguage; }
}

function switchLanguage(language)
{
	var dash					= language.indexOf("-");
	var language				= dash > 0 ? language.substring(0, dash) : language;

	if ($('#language-' + language).length == 0) { language = "nl"; }

	$('.current-language').each(function() {
		this.className = $('#language-' + language + " .flag")[0].className;
		$(this).css('visibility', 'visible');
	});

	localStorage.setItem('language', language);

	$.post("/language", { "language" :  language }, function(data){
		var response = jQuery.parseJSON(data);

		if (response.changed)	{ window.location.reload(); }
	});
}

function getInfiniteScrollPostJSONParams()
{
	var filters					= {};
	var search					= $("#search").val();
	var tmp;

	$( ".cb_filter:checked" ).each(function( index )
	{
		tmp						= filters[$(this).attr("name")];

		if (!tmp)				{ tmp = []; }

		tmp.push($(this).val());

		filters[$(this).attr("name")] = tmp;
	});

	return JSON.stringify({"filters": filters, "search": search});
}

function getFilterAmounts()
{
	$.ajax({
		type: "POST",
		url: "/FunctionsServlet?action=nres&domcod=" + $('.results').data("domcod"),
		data: getInfiniteScrollPostJSONParams(),
		contentType: 'application/json',
		success: function( data ) {
			var tmp, tmpval;
			$("#filter_categories .form-check-label span.nres").each(function( index )
			{
				tmp					= $(this).parent().get(0).htmlFor.right("_");
				tmpval				= JSO.toInt(data.vrdsrtcods["" + tmp]);

				if (tmpval == 0)	{ $(this).text(""); }
				else				{ $(this).text("(" + tmpval + ")"); }
			});
			$("#filter_makes .form-check-label span.nres").each(function( index )
			{
				tmp					= $(this).parent().get(0).htmlFor.right("_");
				tmpval				= JSO.toInt(data.merken["" + tmp]);

				if (tmpval == 0)	{ $(this).text(""); }
				else				{ $(this).text("(" + tmpval + ")"); }
			});
			$("#filter_structures .form-check-label span.nres").each(function( index )
			{
				tmp					= $(this).parent().get(0).htmlFor.right("_");
				tmpval				= JSO.toInt(data.opbouwen["" + tmp]);

				if (tmpval == 0)	{ $(this).text(""); }
				else				{ $(this).text("(" + tmpval + ")"); }
			});
		}
	});
}

function openProduct(item)
{
	if (JSO.toString($(item).data("purl")) != "")	{ window.location.href = $(item).data("purl"); }
	else											{ window.location.href = "/product/a" + JSO.toInt($(item).data("vrdunid")) + ".html"; }
}

